import { UserService } from 'src/app/services/user/user.service';
import { ConfirmationService } from '../../../services/confirmation-service/confirmation.service';
import icLock from '@iconify/icons-ic/twotone-lock';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import icClose from '@iconify/icons-ic/twotone-close';
import icVisibility from '@iconify/icons-ic/twotone-visibility';
import icVisibilityOff from '@iconify/icons-ic/twotone-visibility-off';

import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  ValidationErrors,
  FormBuilder,
} from '@angular/forms';

@Component({
  selector: 'wilmish-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss'],
})
export class PasswordDialog implements OnInit {
  changePassword: FormGroup = this.formBuilder.group(
    {
      currentPassword: new FormControl('', [
        Validators.required,
        Validators.min(3),
      ]),
      newPassword: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}'),
      ]),
      repeatNewPassword: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}'),
      ]),
    },
    { validator: passwordMatchValidator }
  );

  hideCurrent = true;
  hideNew = true;
  hideRepeat = true;

  get currentPasswordInput() {
    return this.changePassword.get('currentPassword');
  }
  get newPasswordInput() {
    return this.changePassword.get('newPassword');
  }
  get repeatNewPasswordInput() {
    return this.changePassword.get('repeatNewPassword');
  }

  icVisibility = icVisibility;
  icClose = icClose;
  icVisibilityOff = icVisibilityOff;
  icLock = icLock;
  constructor(
    private dialogRef: MatDialogRef<any>,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    // private snackBService: SnackBarService,
    private userService: UserService
  ) {}

  ngOnInit() {}

  save() {
    this.confirmationService
      .openConfirmation('Ar tikrai norite pakeisti slaptažodį')
      .subscribe((answer) => {
        if (answer) {
          this.userService
            .changePassword({
              password: this.currentPasswordInput.value,
              newPassword: this.newPasswordInput.value,
            })
            .subscribe((reponse) => {
              this.dialogRef.close();
            });
        }
      });
  }
  onPasswordInput() {
    if (this.changePassword.hasError('passwordMismatch'))
      this.repeatNewPasswordInput.setErrors([{ passwordMismatch: true }]);
    else this.repeatNewPasswordInput.setErrors(null);
  }
}

export const passwordMatchValidator: ValidatorFn = (
  formGroup: FormGroup
): ValidationErrors | null => {
  if (
    formGroup.get('newPassword').value ===
    formGroup.get('repeatNewPassword').value
  )
    return null;
  else return { passwordMismatch: true };
};
