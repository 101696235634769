import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import {
  NavigationDropdown,
  NavigationItem,
} from '../@vex/interfaces/navigation-item.interface';
import { Settings } from 'luxon';
import { AuthService } from './services/auth/auth.service';
import { ConfigService } from '../@vex/services/config.service';
import { LayoutService } from '../@vex/services/layout.service';
import { NavigationService } from '../@vex/services/navigation.service';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import { ConfigName } from '../@vex/interfaces/config-name.model';
import { Role } from './enums/role.enum';
import { Subscription } from 'rxjs';
import icLayers from '@iconify/icons-ic/twotone-layers';
import icHistory from '@iconify/icons-ic/twotone-history';
import icCalculate from '@iconify/icons-ic/twotone-calculate';
import icAdminPanel from '@iconify/icons-ic/twotone-admin-panel-settings';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  currentUser: any;
  subscription: Subscription;
  items: NavigationItem[] = [];

  constructor(
    private authService: AuthService,
    private configService: ConfigService,
    private styleService: StyleService,
    private platform: Platform,
    private layoutService: LayoutService,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string
  ) {
    Settings.defaultLocale = this.localeId;
    if (this.platform.BLINK) {
      this.document.body.classList.add('is-blink');
    }
  }

  ngOnInit(): void {
    this.initializeAppConfig();
    this.initializeNavigationItems();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private initializeAppConfig(): void {
    this.subscription = this.route.queryParamMap.subscribe((queryParamMap) => {
      const layout = queryParamMap.get('layout') as ConfigName;
      const style = queryParamMap.get('style') as Style;
      const isRtl =
        queryParamMap.has('rtl') && queryParamMap.get('rtl') === 'true';

      this.document.body.dir = isRtl ? 'rtl' : 'ltr';

      this.configService.updateConfig({ rtl: isRtl });
      if (layout) this.configService.setConfig(layout);
      if (style) this.styleService.setStyle(style);
    });
  }

  private initializeNavigationItems(): void {
    const administratorDropdownRoles = [Role.ROLE_ADMIN];
    const managerDropDownRoles = [Role.ROLE_MANAGER];

    const usersLink = {
      type: 'link',
      label: 'Users',
      route: '/administrator/users',
    };
    const clientsLink = {
      type: 'link',
      label: 'Clients',
      route: '/manager/clients',
    };

    const historyLink: NavigationItem = {
      type: 'link',
      label: 'Box Calculator History',
      route: '/history',
      icon: icHistory,
      routerLinkActiveOptions: { exact: true },
    };

    const pagrindinis: NavigationItem = {
      type: 'link',
      label: 'Home',
      route: '/home',
      icon: icLayers,
      routerLinkActiveOptions: { exact: true },
    };

    const calculator: NavigationItem = {
      type: 'link',
      label: 'Box Calculator',
      route: '/calculator',
      icon: icCalculate,
      routerLinkActiveOptions: { exact: true },
    };

    const boxCalculatorConfig: NavigationItem = {
      type: 'link',
      label: 'Calculator Configuration',
      route: '/administrator/box-calculator-config',
      routerLinkActiveOptions: { exact: true },
    };

    let administrator: NavigationDropdown = {
      type: 'dropdown',
      label: 'Administrator',
      icon: icAdminPanel,
      children: [],
    };

    let manager: NavigationDropdown = {
      type: 'dropdown',
      label: 'Mananger',
      icon: icAdminPanel,
      children: [],
    };
    // const customizeHeader: NavigationItem = {
    //   type: 'subheading',
    //   label: 'Configuration',
    //   children: [],
    // };

    // const configuration: NavigationItem = {
    //   type: 'link',
    //   label: 'Themes',
    //   route: () => this.layoutService.openConfigpanel(),
    //   icon: icFormatColorFill,
    // };

    this.authService.currentUser.subscribe((user) => {
      this.currentUser = user;

      this.items = [calculator];

      // Build administrator navigation
      if (
        user?.user?.roles?.some((role) =>
          administratorDropdownRoles.includes(role.name)
        )
      ) {
        let dropdownLinks = [];
        dropdownLinks.push(usersLink);

        if (user?.user?.roles?.some((role) => role.name === Role.ROLE_ADMIN)) {
          dropdownLinks.push(boxCalculatorConfig);
        }

        administrator.children = dropdownLinks;

        this.items.push(historyLink);
        this.items.push(administrator);
      } else if (
        user?.user?.roles?.some((role) =>
          managerDropDownRoles.includes(role.name)
        )
      ) {
        let dropdownLinks = [];
        dropdownLinks.push(clientsLink);
        manager.children = dropdownLinks;

        this.items.push(historyLink);
        this.items.push(manager);
      }

      // this.items.push(configuration);

      this.navigationService.triggerNavigationChange(this.items);
    });
  }
}
