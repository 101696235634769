import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { ConfirmationDialogComponent } from 'src/@wilmish/components/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  constructor(private dialog: MatDialog) {}

  openConfirmation(message) {
    return this.dialog
      .open(ConfirmationDialogComponent, {
        width: '300px',
        data: message,
      })
      .afterClosed();
  }
}
