import { UserService } from 'src/app/services/user/user.service';

import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';
import icDone from '@iconify/icons-ic/twotone-done-outline';
import icCancel from '@iconify/icons-ic/twotone-cancel';
import { User } from 'src/app/models/user.model';
import { Transfer } from 'src/@wilmish/models/transfer.model';
import { ConfirmationService } from 'src/@wilmish/services/confirmation-service/confirmation.service';
@Component({
  selector: 'wilmish-transfer-modal',
  templateUrl: './transfer-modal.component.html',
  styleUrls: ['./transfer-modal.component.scss'],
})
export class TransferModalComponent implements OnInit {
  icClose = icClose;
  icDone = icDone;
  icCancel = icCancel;
  transfer: Transfer;
  noChanges: Boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<TransferModalComponent>,
    private userService: UserService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    if (this.defaults?.context === 'Roles') {
      this.transfer = {
        transfered:
          this.defaults.user.roles?.map((role) => role.displayName) || [],
        available: this.defaults.roles.map((role) => role.displayName) || [],
      };
    }
  }

  updateTransfer(event) {
    this.transfer = event;
    if (this.defaults?.context === 'Roles') this.prepareNewRoleList();
  }

  save() {
    if (this.defaults?.context === 'Roles') {
      this.confirmationService
        .openConfirmation('Are you sure you want to change user roles?')
        .subscribe((answer) => {
          if (answer) {
            this.defaults.user.roles = this.transfer.transfered;
            let patchUser = new User({
              id: this.defaults.user.id,
              roles: this.defaults.user.roles,
            });
            this.userService.patchUser(patchUser).subscribe((user) => {
              this.dialogRef.close(this.defaults.user);
            });
          }
        });
    }
  }

  prepareNewRoleList() {
    let roleList = this.defaults.roles.filter((role) =>
      this.transfer.transfered.includes(role.displayName)
    );
    if (this.transfer.transfered === roleList) this.noChanges = true;
    else this.noChanges = false;
    this.transfer.transfered = roleList;
  }
}
