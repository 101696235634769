<form (ngSubmit)="save()" [formGroup]="form" *ngIf="managerList">
  <div
    class="card relative trans-shadow shadow-20 w-full h-full shadow"
    [ngClass]="mode === 'update' ? 'bordered' : ''"
  >
    <!-- <div *ngIf="mode !== 'new'"> -->
    <div class="flex">
      <h4 style="margin: auto; margin-left: 1rem; padding-top: 0.5rem">
        Profile
      </h4>

      <div [ngSwitch]="mode" *ngIf="canEdit">
        <button
          class="text-secondary"
          mat-icon-button
          type="button"
          *ngSwitchCase="'watch'"
          (click)="updateMode()"
        >
          <mat-icon [icIcon]="icEdit" color="primary"></mat-icon>
        </button>

        <button
          [disabled]="getErrorCount(form) > 0 || !areChanges()"
          class="text-secondary"
          mat-icon-button
          type="button"
          (click)="update()"
          *ngSwitchCase="'update'"
        >
          <mat-icon
            *ngIf="getErrorCount(form) === 0 && areChanges()"
            [icIcon]="icDone"
            color="green"
          ></mat-icon>
          <mat-icon
            *ngIf="getErrorCount(form) > 0 || !areChanges()"
            [icIcon]="icDone"
          ></mat-icon>
        </button>

        <button
          class="text-secondary"
          mat-icon-button
          type="button"
          *ngSwitchCase="'update'"
          (click)="cancelUpdate()"
        >
          <mat-icon [icIcon]="icCancel" color="red"></mat-icon>
        </button>
      </div>
    </div>
    <div
      class="px-gutter py-4 pl-6 pt-0"
      gdColumns="1fr"
      gdColumns.xs="1fr"
      gdGap="3px"
    >
      <div style="margin: auto">
        <ngx-avatar
          size="150"
          [name]="
            form.get('firstName').value + ' ' + form.get('lastName').value
          "
        ></ngx-avatar>
      </div>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input
          cdkFocusInitial
          formControlName="firstName"
          matInput
          [readonly]="mode === 'watch'"
        />
        <mat-error *ngIf="form.get('firstName').errors?.required"
          >Mandatory</mat-error
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label>Lastname</mat-label>
        <input
          cdkFocusInitial
          formControlName="lastName"
          matInput
          [readonly]="mode === 'watch'"
        />
        <mat-error *ngIf="form.get('lastName').errors?.required"
          >Mandatory</mat-error
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label>Email</mat-label>
        <input
          cdkFocusInitial
          formControlName="email"
          matInput
          [readonly]="mode != 'create'"
        />
        <mat-error *ngIf="form.get('email').errors?.required"
          >*Mandatory
        </mat-error>
        <mat-error
          *ngIf="
            form.get('email').errors?.emailExist &&
            getControlsErrorCount(form.get('email')) === 1 &&
            mode != 'watch'
          "
          >*Email already exist
        </mat-error>
        <mat-error *ngIf="form.get('email').errors?.email"
          >*Bad email format
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Mobile number</mat-label>
        <input
          type="text"
          formControlName="phoneNumber"
          matInput
          [readonly]="mode === 'watch'"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Location</mat-label>
        <input
          *ngIf="mode === 'watch'"
          cdkFocusInitial
          formControlName="transportationLocation"
          matInput
          [readonly]="mode === 'watch'"
        />

        <mat-select
          formControlName="transportationLocation"
          *ngIf="mode === 'create' || mode === 'update'"
        >
          <mat-option
            *ngFor="let transport of locations"
            [value]="transport.location"
          >
            {{ transport.location }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Company</mat-label>
        <input
          cdkFocusInitial
          formControlName="company"
          matInput
          [readonly]="mode === 'watch'"
        />
      </mat-form-field>
      <mat-form-field *ngIf="canEdit">
        <mat-label>MANAGED BY</mat-label>
        <input
          *ngIf="mode === 'watch'"
          cdkFocusInitial
          formControlName="managedBy"
          matInput
          [readonly]="mode === 'watch'"
        />

        <mat-select
          formControlName="managedBy"
          *ngIf="mode === 'create' || mode === 'update'"
        >
          <mat-option *ngFor="let manager of managerList" [value]="manager.id">
            {{ manager.fullName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div
        style="padding: 5px"
        *ngIf="
          (marginPriceRanges.controls.length > 0 ||
            mode === 'update' ||
            mode === 'create') &&
          canEdit
        "
      >
        <div
          fxLayout="row"
          fxLayoutAlign="start start"
          fxLayoutGap="10px"
          style="padding-bottom: 10px"
        >
          <label class="lable">Margin Price Ranges</label>
          <button
            mat-icon-button
            color="primary"
            (click)="addRange('', '')"
            *ngIf="mode === 'update' || mode === 'create'"
            type="button"
          >
            <mat-icon
              style="font-size: xx-large"
              [icIcon]="icAdd"
              color="primary"
            ></mat-icon>
          </button>
        </div>
        <div
          formArrayName="marginPriceRanges"
          *ngFor="let range of marginPriceRanges.controls; let i = index"
        >
          <div [formGroupName]="i">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <mat-label class="mat-form-field-wrapper lable">{{
                i == 0 ? 'From' : 'from'
              }}</mat-label>
              <mat-form-field>
                <mat-label>Order Area</mat-label>
                <input
                  type="number"
                  matInput
                  formControlName="fromArea"
                  [readonly]="mode === 'watch'"
                />
                <span matSuffix style="text-transform: lowercase">m²</span>
              </mat-form-field>
              <mat-label class="mat-form-field-wrapper lable">apply</mat-label>
              <mat-form-field style="min-width: 100px !important">
                <mat-label>Margin Price</mat-label>
                <input
                  type="number"
                  matInput
                  formControlName="marginPrice"
                  [readonly]="mode === 'watch'"
                />
                <span matSuffix>€</span>
              </mat-form-field>
              <button
                *ngIf="mode === 'update' || mode === 'create'"
                type="button"
                (click)="removeRange(i)"
                class="mat-form-field-wrapper"
              >
                <mat-icon
                  style="font-size: xx-large"
                  [icIcon]="icDelete"
                  color="warn"
                ></mat-icon>
              </button>
              <label class="lable">{{
                marginPriceRanges.controls.length > 0
                  ? marginPriceRanges.controls.length - 1 != i
                    ? ','
                    : '.'
                  : '.'
              }}</label>
            </div>
          </div>
        </div>
      </div>
      <button
        *ngIf="mode === 'create'"
        class="mr-2"
        submit
        mat-flat-button
        [disabled]="getErrorCount(form) > 0"
        type="button"
        color="primary"
        matTooltip="Create new user"
        type="submit"
      >
        CREATE
      </button>
    </div>
  </div>
</form>
