<div
  class="p-gutter"
  gdColumns="1fr 1fr"
  gdColumns.lt-xl="1fr 1fr"
  gdColumns.lt-lg="1fr 1fr"
  gdColumns.lt-md="1fr"
  gdColumns.lt-sm="1fr"
  gdGap="10px"
  vexContainer
  style="overflow: auto"
>
  <div class="example-container non card trans-shadow shadow-20">
    <h4 class="head">Unassigned</h4>
    <div
      cdkDropList
      #todoList="cdkDropList"
      [cdkDropListData]="todo"
      [cdkDropListConnectedTo]="[doneList]"
      class="example-list"
      (cdkDropListDropped)="drop($event)"
    >
      <div class="example-box" *ngFor="let item of todo" cdkDrag>
        {{ item }}
      </div>
    </div>
  </div>

  <div class="example-container non card trans-shadow shadow-20">
    <h4 class="head">Assigned</h4>
    <div
      cdkDropList
      #doneList="cdkDropList"
      [cdkDropListData]="done"
      [cdkDropListConnectedTo]="[todoList]"
      class="example-list"
      (cdkDropListDropped)="drop($event)"
    >
      <div class="example-box" *ngFor="let item of done" cdkDrag>
        {{ item }}
      </div>
    </div>
  </div>
</div>
