<div
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async"
  class="sidenav flex flex-col overflow-y-hidden"
>
  <div class="sidenav-toolbar flex-none flex items-center">
    <!-- <img [src]="imageUrl$ | async" class="w-6 select-none flex-none" /> -->

    <!-- <mat-icon [icIcon]="icArchitecture"></mat-icon> -->

    <img [src]="imageUrl2$ | async" class="logo-svg select-none flex-auto" />
    <!-- <h2 class="title ltr:pl-4 rtl:pr-4 select-none flex-auto">
      {{ title$ | async }}
    </h2> -->
    <button
      (click)="toggleCollapse()"
      *ngIf="showCollapsePin$ | async"
      class="h-8 title leading-none flex-none hidden lg:block"
      mat-icon-button
      type="button"
    >
      <mat-icon
        *ngIf="!collapsed"
        [icIcon]="icRadioButtonChecked"
        size="14px"
      ></mat-icon>
      <mat-icon
        *ngIf="collapsed"
        [icIcon]="icRadioButtonUnchecked"
        size="14px"
      ></mat-icon>
    </button>
  </div>

  <!-- <vex-scrollbar class="flex-auto"> -->
  <div class="sidenav-items flex-auto" style="overflow: auto">
    <vex-sidenav-item
      *ngFor="let item of items | async; trackBy: trackByRoute"
      [item]="item"
      [level]="0"
    ></vex-sidenav-item>
  </div>
  <!-- </vex-scrollbar> -->

  <div class="sidenav-toolbar flex-none flex">
    <div fxLayout="row" fxFlexAlign="center">
      <p class="flex-auto title" style="font-size: 0.6rem">Powered by :</p>
      <a href="https://wilmish.lt"
        ><img
          class="flex-auto title logo-wilmish-svg"
          [src]="imageUrl$ | async"
      /></a>
    </div>
  </div>
</div>
