<!-- <div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
  <h2 class="headline m-0" fxFlex="auto">Pakeisti</h2>
  <mat-icon [icIcon]="icClose"></mat-icon>
  <h2 class="headline m-0" fxFlex="auto">Pakeisti</h2>
  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon [icIcon]="icClose"></mat-icon>
  </button>
</div> -->

<mat-dialog-actions class="flex" style="margin-top: -20px; margin-bottom: 0px">
  <!-- <button mat-button cdkFocusInitial color="primary" (click)="save()">
    IŠSAUGOTI
  </button> -->

  <h2 style="margin: auto; margin-left: 1rem">Roles selection</h2>

  <button
    class="text-secondary"
    mat-icon-button
    type="button"
    [disabled]="noChanges"
    (click)="save()"
  >
    <mat-icon *ngIf="!noChanges" [icIcon]="icDone" color="green"></mat-icon>
    <mat-icon *ngIf="noChanges" [icIcon]="icDone"></mat-icon>
  </button>

  <button class="text-secondary" mat-icon-button type="button" mat-dialog-close>
    <mat-icon [icIcon]="icCancel" color="red"></mat-icon>
  </button>
</mat-dialog-actions>
<mat-divider class="text-border"></mat-divider>
<mat-dialog-content>
  <wilmish-transfer
    [transfer]="transfer"
    (updateTransfer)="updateTransfer($event)"
  ></wilmish-transfer>
</mat-dialog-content>
