import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { AuthGuard } from './services/guard/auth.guard';
import { Role } from './enums/role.enum';

const routes: VexRoutes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'administrator',
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_ADMIN],
        },
        children: [
          {
            path: 'users',
            data: {
              roles: [Role.ROLE_ADMIN],
              scrollDisabled: true,
              toolbarShadowEnabled: true,
            },
            canActivate: [AuthGuard],
            loadChildren: () =>
              import('./pages/users/users.module').then((m) => m.UsersModule),
          },
          {
            path: 'box-calculator-config',
            data: {
              roles: [Role.ROLE_ADMIN],
              scrollDisabled: true,
              toolbarShadowEnabled: true,
            },
            canActivate: [AuthGuard],
            loadChildren: () =>
              import(
                './pages/box-calculator-config/box-calculator-config.module'
              ).then((m) => m.BoxCalculatorConfigModule),
          },
        ],
      },
      {
        path: 'manager',
        canActivate: [AuthGuard],
        data: {
          roles: [Role.ROLE_MANAGER],
        },
        children: [
          {
            path: 'clients',
            data: {
              roles: [Role.ROLE_MANAGER],
              scrollDisabled: true,
              toolbarShadowEnabled: true,
            },
            canActivate: [AuthGuard],
            loadChildren: () =>
              import('./pages/clients/clients.module').then(
                (m) => m.ClientsModule
              ),
          },
        ],
      },
      {
        path: 'home',
        data: {
          scrollDisabled: true,
          toolbarShadowEnabled: true,
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'history',
        data: {
          scrollDisabled: true,
          toolbarShadowEnabled: true,
          roles: [Role.ROLE_MANAGER, Role.ROLE_ADMIN],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/history/history.module').then((m) => m.HistoryModule),
      },
      {
        path: 'calculator',
        data: {
          scrollDisabled: true,
          toolbarShadowEnabled: true,
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./pages/calculator/calculator.module').then(
            (m) => m.CalculatorModule
          ),
      },
      { path: '**', redirectTo: 'calculator' },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'corrected',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
