import { UserFilter } from 'src/app/models/filters/userFilter.interface';
import { ChangePassword } from '../../models/password.model';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseUrl = environment.apiUrl + 'users'; // URL to web api
  private PASSWORD = this.baseUrl + '/password'; // URL to web api

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    params: new HttpParams(),
  };

  constructor(private http: HttpClient) {}

  getUserList(filter?, pageNumber?, pageSize?) {
    this.applyPagination(pageNumber, pageSize);
    this.applyFilter(filter);

    return this.http.post(this.baseUrl + '/get', filter.list, {
      params: this.httpOptions.params,
      headers: this.httpOptions.headers,
      observe: 'response',
    });
  }
  getUsers(filter): Observable<User[]> {
    return this.http.post<any[]>(this.baseUrl + '/get', filter.list);
  }
  getUserFieldsValues(field, value): Observable<any[]> {
    return this.http.get<any[]>(
      this.baseUrl + '/values?field' + '=' + field + '&value=' + value
    );
  }
  generatePassword(user: User) {
    const url = `${this.baseUrl}/${user.id}/password`;
    return this.http.get(url).pipe(
      tap((_) => this.log(`id=${user.id}`)),
      catchError(this.handleError(`id=${user.id}`))
    );
  }

  /** GET detail by id. Will 404 if id not found */
  getUser(id: number): Observable<User> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<User>(url).pipe(
      tap((_) => this.log(`fetched detail id=${id}`)),
      catchError(this.handleError<User>(`getUser id=${id}`))
    );
  }

  applyFilter(filter?: any) {
    this.httpOptions.params = new HttpParams();

    if (
      filter.sort != null &&
      filter.sort != undefined &&
      filter.sort.size !== 0
    ) {
      filter.sort.forEach((value: string, key: string) => {
        if (value !== '') {
          this.httpOptions.params = this.httpOptions.params.append(
            'sort',
            key + ',' + value
          );
        }
      });
    }

    if (filter.role != null && filter.role.length !== 0) {
      let list = '';
      filter.role.forEach((value: string) => {
        list = list + value + ',';
      });
      this.httpOptions.params = this.httpOptions.params.append(
        'roleName',
        list
      );
    }

    if (filter.project != null && filter.project.length !== 0) {
      let list = '';
      filter.project.forEach((value: string) => {
        list = list + value + ',';
      });
      this.httpOptions.params = this.httpOptions.params.append(
        'projectNumber',
        list
      );
    }
  }

  /* GET detailes whose name contains search term */
  searchUsers(term: string): Observable<User[]> {
    if (!term.trim()) {
      // if not search term, return empty detail array.
      return of([]);
    }
    return this.http.get<User[]>(`${this.baseUrl}?name=${term}`).pipe(
      tap((x) =>
        x.length
          ? this.log(`found detailes matching "${term}"`)
          : this.log(`no detailes matching "${term}"`)
      ),
      catchError(this.handleError<User[]>('searchUseres', []))
    );
  }

  /** POST: add a new user to the server */
  addUser(user: User): Observable<User> {
    this.applyFilter({ gender: 'male' });
    return this.http.post<User>(this.baseUrl, user, this.httpOptions).pipe(
      tap((newUser: User) => this.log(`added detail w/ id=${newUser.id}`)),
      catchError(this.handleError<User>('addUser'))
    );
  }

  /** DELETE: delete the detail from the server */
  deleteUser(detail: User | number): Observable<User> {
    const id = typeof detail === 'number' ? detail : detail.id;
    const url = `${this.baseUrl}/${id}`;

    return this.http.delete<User>(url, this.httpOptions).pipe(
      tap((_) => this.log(`deleted detail id=${id}`)),
      catchError(this.handleError<User>('deleteUser'))
    );
  }

  changePassword(password: ChangePassword) {
    return this.http.patch(this.PASSWORD, password, this.httpOptions);
  }

  /** PATCH: update the user on the server */
  patchUser(user): Observable<any> {
    const url = `${this.baseUrl}/${user.id}`;
    return this.http.patch(url, user, this.httpOptions).pipe(
      tap((_) => this.log(`updated detail id=${user.id}`)),
      catchError(this.handleError<any>('updateUser'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a UserService message with the MessageService */
  private log(message: string) {
    // this.messageService.add(`UserService: ${message}`);
  }

  applyPagination(pageNumber, pageSize) {
    this.httpOptions.headers = this.httpOptions.headers.set(
      'page-number',
      pageNumber
    );
    this.httpOptions.headers = this.httpOptions.headers.set(
      'page-size',
      pageSize
    );
  }
}
