import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { Project } from 'src/app/models/project.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private baseUrl = environment.apiUrl + 'projects';
  private usersProjectsUrl = environment.apiUrl + 'users/projects';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    params: new HttpParams(),
  };

  currentProjectSubject: BehaviorSubject<number> = new BehaviorSubject<number>(
    Number(localStorage.getItem('project')) || 1
  );

  // currentProject = localStorage.getItem('project');

  constructor(private http: HttpClient) {}

  getUsersProjectList(): Observable<Project[]> {
    return this.http.get<Project[]>(this.usersProjectsUrl).pipe(
      tap((_) => this.log('fetched detailes')),
      catchError(this.handleError<Project[]>('getProjects', []))
    );
  }

  getProjectList(filter?) {
    // this.applyPagination(pageNumber, pageSize);
    this.applyFilter(filter);

    return this.http.post(this.baseUrl + '/get', filter.list, {
      params: this.httpOptions.params,
      headers: this.httpOptions.headers,
      observe: 'response',
    });
  }
  getProjectFieldsValues(field, value): Observable<any[]> {
    return this.http.get<any[]>(
      this.baseUrl + '/values?field' + '=' + field + '&value=' + value
    );
  }
  /** PATCH: update the user on the server */
  patchProject(project): Observable<any> {
    const url = `${this.baseUrl}/${project.id}`;
    return this.http.patch(url, project, this.httpOptions).pipe(
      tap((_) => this.log(`updated id=${project.id}`)),
      catchError(this.handleError<any>(''))
    );
  }
  // getProjectList(): Observable<Project[]> {
  //   return this.http.get<Project[]>(this.baseUrl).pipe(
  //     tap((_) => this.log('fetched detailes')),
  //     catchError(this.handleError<Project[]>('getProjects', []))
  //   );
  // }

  /** DELETE: delete the project from the server */
  deleteProject(order) {
    const url = `${this.baseUrl}/${order.id}`;
    return this.http.delete(url, this.httpOptions);
  }

  public get currentUserValue(): any {
    return this.currentProjectSubject.value;
  }

  setCurrentProject(projectId) {
    this.currentProjectSubject.next(projectId);
    localStorage.setItem('project', projectId);
  }

  /** GET project by id. Will 404 if id not found */
  getProject(id: number): Observable<Project> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<Project>(url).pipe(
      tap((_) => this.log(`fetched project id=${id}`)),
      catchError(this.handleError<Project>(`getProject id=${id}`))
    );
  }

  //////// Save methods //////////

  /** POST: add a new project to the server */
  addProject(project: Project): Observable<Project> {
    return this.http
      .post<Project>(this.baseUrl, project, this.httpOptions)
      .pipe(
        tap((newProject: Project) =>
          this.log(`added project w/ id=${newProject.id}`)
        ),
        catchError(this.handleError<Project>('addProject'))
      );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a ProjectService message with the MessageService */
  private log(message: string) {
    // this.messageService.add(`ProjectService: ${message}`);
  }

  applyFilter(filter: any) {
    this.httpOptions.params = new HttpParams();

    if (filter) {
      if (filter.number != null && filter.number != '') {
        this.httpOptions.params = this.httpOptions.params.set(
          'projectNumber',
          filter.number
        );
      }
      if (filter.name != null && filter.name != '') {
        this.httpOptions.params = this.httpOptions.params.set(
          'projectName',
          filter.name
        );
      }

      if (filter.sort != null && filter.sort.size !== 0) {
        filter.sort.forEach((value: string, key: string) => {
          if (value !== '') {
            this.httpOptions.params = this.httpOptions.params.append(
              'sort',
              key + ',' + value
            );
          }
        });
      }
    }
  }

  applyPagination(pageNumber, pageSize) {
    if (!(pageNumber === null || pageSize === null)) {
      this.httpOptions.headers = this.httpOptions.headers.set(
        'page-number',
        pageNumber
      );
      this.httpOptions.headers = this.httpOptions.headers.set(
        'page-size',
        pageSize
      );
    }
  }
}
