import { Router } from '@angular/router';
import { SnackBarService } from 'src/@wilmish/services/snack-bar-service/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ErrorDialogComponent } from 'src/@wilmish/components/error-dialog/error-dialog.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private snack: SnackBarService,
    private router: Router
  ) {}
  private _dialog: MatDialog;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this._dialog) {
      this._dialog = this.injector.get(MatDialog);
    }

    return next.handle(request).pipe(
      tap((response: any) => {
        if ([204].indexOf(response.status) !== -1) {
          this.snack.verticalPosition = 'top';
          this.snack.openSnackBar('Information successfully saved');
        }
      }),
      catchError(async (err) => {
        if ([400, 500, 503, 404].indexOf(err.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          let error;
          if (err.error instanceof Blob) {
            error = JSON.parse(await err.error.text()).message;
          } else {
            error = err.error.message;
          }
          this.showErrorDialog(error);
          // window.alert(error);
          return throwError(err);
          // } else if ([401, 403].indexOf(err.status) !== -1) {
        } else if ([401].indexOf(err.status) !== -1) {
          this.router.navigateByUrl('/login');
          this.snack.openSnackBar('Jūsų sesija baigėsi');
          return throwError(err);
        }

        return throwError(() => new Error());
      })
    );
  }
  private showErrorDialog(errorMessage: string) {
    this._dialog.open(ErrorDialogComponent, {
      data: errorMessage,
      width: '300px',
    });
  }
}
