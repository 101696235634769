<table
  [dataSource]="dataSource"
  class="w-full"
  mat-table
  matSort
  [@stagger]="dataSource"
>
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef style="width: 100px">Nr.</th>
    <td mat-cell *matCellDef="let item; let i = index">
      <span>{{ i + 1 }}</span>
    </td>
  </ng-container>
  <!-- Text Columns -->
  <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
    <ng-container
      *ngIf="column.type === 'text'"
      [matColumnDef]="column.property"
    >
      <th
        *matHeaderCellDef
        class="uppercase"
        mat-header-cell
        [mat-sort-header]="column.property"
      >
        {{ column.label }}
      </th>
      <td
        [ngSwitch]="column.property"
        *matCellDef="let row"
        [ngClass]="column.cssClasses"
        mat-cell
      >
        <div *ngSwitchCase="'price'">
          {{ row[column.property] }}
        </div>
        <span *ngSwitchDefault>{{ row[column.property] }}</span>
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="visibleColumns; sticky: true"></tr>
  <tr
    (dblclick)="openRow.emit(row)"
    (click)="openRow.emit(row)"
    [class.selected-row]="selection.isSelected(row)"
    (click)="selection.toggle(row)"
    *matRowDef="let row; columns: visibleColumns"
    @fadeInUp
    class="hover:bg-hover trans-ease-out cursor-pointer"
    mat-row
  ></tr>
</table>
