<div class="card relative trans-shadow shadow-20 w-full h-full shadow">
  <div class="flex">
    <h4 style="margin: auto; margin-left: 1rem; padding-top: 0.5rem">Roles</h4>
    <button
      *ngIf="canEdit"
      class="text-secondary"
      mat-icon-button
      type="button"
      (click)="openEditRolesList()"
    >
      <mat-icon [icIcon]="icEdit" color="primary"></mat-icon>
    </button>
  </div>
  <wilmish-data-table
    [columns]="rolesColumns"
    [data]="roleList"
  ></wilmish-data-table>
</div>
