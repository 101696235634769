<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
  <mat-icon
    fxFlex="auto"
    [icIcon]="icLock"
    size="30px"
    class="text-primary -mt-5"
  ></mat-icon>
  <label fxFlex="auto">Change password</label>
  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon [icIcon]="icClose"></mat-icon>
  </button>
</div>

<mat-dialog-content>
  <form [formGroup]="changePassword" fxLayout="column" fxLayoutGap="10px">
    <mat-form-field fxFlex="auto">
      <mat-label>Current password</mat-label>
      <input
        matInput
        placeholder="Password"
        [type]="hideCurrent ? 'password' : 'text'"
        formControlName="currentPassword"
        required
      />
      <mat-icon
        *ngIf="!hideCurrent"
        matSuffix
        [icIcon]="icVisibility"
        (click)="hideCurrent = true"
      ></mat-icon>
      <mat-icon
        *ngIf="hideCurrent"
        matSuffix
        [icIcon]="icVisibilityOff"
        (click)="hideCurrent = false"
      ></mat-icon>
      <mat-hint *ngIf="!currentPasswordInput.value"
        >Enter current password</mat-hint
      >
    </mat-form-field>
    <mat-form-field fxFlex="auto">
      <mat-label>New password</mat-label>
      <input
        (input)="onPasswordInput()"
        matInput
        placeholder="Slaptažodis"
        [type]="hideNew ? 'password' : 'text'"
        formControlName="newPassword"
        required
      />
      <mat-icon
        *ngIf="!hideNew"
        matSuffix
        [icIcon]="icVisibility"
        (click)="hideNew = true"
      ></mat-icon>
      <mat-icon
        *ngIf="hideNew"
        matSuffix
        [icIcon]="icVisibilityOff"
        (click)="hideNew = false"
      ></mat-icon>
      <mat-hint *ngIf="!newPasswordInput.value">
        Password has to have minimum 8 characters and must include 1 number and
        1 capital.
      </mat-hint>
      <mat-error *ngIf="newPasswordInput.hasError('required')"
        >Password mandatory</mat-error
      >
      <mat-error *ngIf="newPasswordInput.hasError('pattern')">
        Password has to have minimum 8 characters and must include 1 number and
        1 capital.</mat-error
      >
    </mat-form-field>
    <mat-form-field fxFlex="auto">
      <mat-label>Repeat new password</mat-label>
      <input
        (input)="onPasswordInput()"
        matInput
        placeholder="slaptažodis"
        [type]="hideRepeat ? 'password' : 'text'"
        formControlName="repeatNewPassword"
        required
      />
      <mat-icon
        *ngIf="!hideRepeat"
        matSuffix
        [icIcon]="icVisibility"
        (click)="hideRepeat = true"
      ></mat-icon>
      <mat-icon
        *ngIf="hideRepeat"
        matSuffix
        [icIcon]="icVisibilityOff"
        (click)="hideRepeat = false"
      ></mat-icon>
      <mat-hint *ngIf="!repeatNewPasswordInput.value"
        >Passwords do not match</mat-hint
      >
      <mat-error *ngIf="repeatNewPasswordInput.hasError('required')"
        >Password repeat mandatory</mat-error
      >
      <mat-error
        *ngIf="
          repeatNewPasswordInput.invalid &&
          !repeatNewPasswordInput.hasError('required')
        "
        >Passwords do not match</mat-error
      >
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    (click)="save()"
    [disabled]="!this.changePassword.valid"
    mat-button
    submit
  >
    Change password
  </button>
</mat-dialog-actions>
