import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import icPhone from '@iconify/icons-ic/twotone-phone';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icMail from '@iconify/icons-ic/twotone-mail';
import icChat from '@iconify/icons-ic/twotone-chat';
import icStar from '@iconify/icons-ic/twotone-star';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icCopy from '@iconify/icons-ic/twotone-content-copy';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icOrder from '@iconify/icons-ic/twotone-add-shopping-cart';
import icStarBorder from '@iconify/icons-ic/twotone-star-border';

import icSettings from '@iconify/icons-ic/twotone-settings';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'wilmish-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent implements OnInit {
  icBusiness = icBusiness;
  icPhone = icPhone;
  icMail = icMail;
  icChat = icChat;
  icStar = icStar;
  icStarBorder = icStarBorder;
  icEdit = icEdit;
  icDelete = icDelete;
  icOrder = icOrder;
  icSettings = icSettings;
  icCopy = icCopy;

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<ErrorDialogComponent>
  ) {}

  ngOnInit() {
    if (!this.defaults) {
      this.defaults = 'Serverio sutrikimai';
    }
  }
}
