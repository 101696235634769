<h2 mat-dialog-title>Data will be changed!</h2>
<mat-dialog-content class="mat-typography">
  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx">
    <h3>{{ message }}</h3>
    <img
      src="/assets/img/illustrations/question.svg"
      style="float: right"
      height="60"
      width="60"
    />
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>NO</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>YES</button>
</mat-dialog-actions>
