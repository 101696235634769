import { Role } from './role.model';

export class User {
  id?: string;
  imageSrc?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  description?: string;
  roles?: Role[];
  active: string;
  marginPriceRanges: any;
  transportationLocation: string;
  company: string;
  managedByUserFullName: string;
  managedByUserId: string;
  fullName?: string;

  constructor(user) {
    this.id = user.id;
    this.imageSrc = user.imageSrc;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.phoneNumber = user.phoneNumber;
    this.email = user.email;
    this.roles = user.roles || null;
    this.description = user.description;
    this.roles =
      user.roles != null ? user.roles.map((role) => new Role(role)) : null;
    this.active = user.active;
    this.marginPriceRanges = user.marginPriceRanges;
    this.transportationLocation = user.transportationLocation;
    this.company = user.company;
    this.managedByUserFullName = user.managedByUserFullName;
    this.managedByUserId = user.managedByUserId;
    this.fullName = user.fullName;
  }

  get name() {
    let name = '';
    if (this.firstName && this.lastName) {
      name = this.firstName + ' ' + this.lastName;
    } else if (this.firstName) {
      name = this.firstName;
    } else if (this.lastName) {
      name = this.lastName;
    }
    return name;
  }

  set name(value) {}

  //   set address(value) {}
}
