<div
  class="navigation"
  fxLayout="row"
  fxLayoutAlign="start center"
  vexContainer
>
  <vex-navigation-item
    *ngFor="let item of items | async"
    [item]="item"
  ></vex-navigation-item>
</div>
