<div
  fxLayout="row"
  fxLayoutAlign="start center"
  mat-dialog-title
  *ngIf="canEdit()"
>
  <div class="inline-flex" fxFlex="auto">
    <h2 style="align-self: center" class="headline pl-4 w-full">
      {{ newUser ? 'New user' : 'User ' }}
      {{ this.defaults?.user?.active === 'No' ? ': Inactive' : '' }}
    </h2>
  </div>
  <button
    class="text-secondary"
    mat-dialog-close
    mat-icon-button
    type="button"
    style="margin-left: 24px"
  >
    <mat-icon [icIcon]="icClose"></mat-icon>
  </button>
</div>

<mat-divider *ngIf="canEdit()" class="-mx-6 text-border"></mat-divider>

<mat-dialog-content>
  <div
    *ngIf="!newUser"
    class="pt-5 pb-5"
    gdColumns="1fr 1fr"
    gdColumns.lt-xl="1fr 1fr"
    gdColumns.lt-lg="1fr 1fr"
    gdColumns.lt-md="1fr 1fr"
    gdColumns.lt-sm="1fr"
    gdGap="10px"
    vexContainer
  >
    <div gdColumns="1fr" gdColumns.xs="1fr" gdGap="10px">
      <div
        class="card relative trans-shadow shadow-20 w-full h-full shadow"
        *ngIf="canEdit()"
      >
        <button
          mat-icon-button
          type="button"
          matTooltip="Generate new password for user"
          (click)="generatePassword()"
        >
          <mat-icon [icIcon]="icLock" color="primary"></mat-icon>
        </button>
        <button
          mat-icon-button
          type="button"
          matTooltip="Disable user"
          [disabled]="this.defaults?.user?.active === 'No'"
          (click)="deactivateUser()"
        >
          <mat-icon
            [icIcon]="icPowerOff"
            color="primary"
            [ngStyle]="{
              color: this.defaults?.user?.active === 'Yes' ? 'primary' : 'gray'
            }"
          ></mat-icon>
        </button>
        <button
          mat-icon-button
          type="button"
          matTooltip="Enable user"
          (click)="activateUser()"
          [disabled]="this.defaults?.user?.active === 'Yes'"
        >
          <mat-icon
            [icIcon]="icPowerOn"
            [ngStyle]="{
              color: this.defaults?.user?.active === 'No' ? 'primary' : 'gray'
            }"
            color="primary"
          ></mat-icon>
        </button>
      </div>
      <wilmish-profile
        [user]="user$ | async"
        (updateUser)="updateUser($event)"
        [canEdit]="canEdit()"
      ></wilmish-profile>
    </div>
    <div gdColumns="1fr" gdColumns.xs="1fr" gdGap="10px">
      <wilmish-roles
        [canEdit]="canEdit()"
        [user]="user$ | async"
      ></wilmish-roles>
    </div>
  </div>

  <div *ngIf="newUser" style="padding-top: 1rem; padding-bottom: 1rem">
    <div gdColumns="1fr" gdColumns.xs="1fr" gdGap="10px">
      <wilmish-profile
        [user]="user$ | async"
        (updateUser)="updateUser($event)"
        [canEdit]="canEdit()"
      ></wilmish-profile>
    </div>
  </div>
</mat-dialog-content>
