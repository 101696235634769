<h2 mat-dialog-title>
  Error!<img class="pl-4" style="height: 30px; float: right" src="/assets/img/illustrations/sad2.png" alt="Vex Logo" />
</h2>

<mat-dialog-content class="mat-typography">
  <!-- <h3 *ngIf="!this.defaults">
    {{ 'Serverio sutrikimai' }}
  </h3> -->
  <div [innerHTML]="this.defaults"></div>
</mat-dialog-content>
<!-- <mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Supratau</button>
</mat-dialog-actions> -->