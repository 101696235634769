import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@visurel/iconify-angular';
import { ToolbarProjectComponent } from './toolbar-project.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [ToolbarProjectComponent],
  imports: [
    CommonModule,
    MatInputModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    IconModule,
    MatSelectModule,
  ],
  exports: [ToolbarProjectComponent],
})
export class ToolbarProjectModule {}
