import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';
import icPerson from '@iconify/icons-ic/twotone-person';
import icPowerOff from '@iconify/icons-ic/twotone-power-off';
import icPowerOn from '@iconify/icons-ic/twotone-power';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/models/user.model';
import icLock from '@iconify/icons-ic/twotone-lock';
import { PasswordResetComponent } from 'src/@wilmish/components/password/password-reset/password-reset/password-reset.component';
import { ConfirmationService } from 'src/@wilmish/services/confirmation-service/confirmation.service';
import { UserService } from 'src/app/services/user/user.service';
@UntilDestroy()
@Component({
  selector: 'user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit {
  newUser: boolean;
  view: boolean;
  icClose = icClose;
  icPowerOff = icPowerOff;
  icPowerOn = icPowerOn;
  icLock = icLock;
  icPerson = icPerson;
  userObserver: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  user$ = this.userObserver.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<UserEditComponent>,
    private dialog: MatDialog,
    private confirmationService: ConfirmationService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.newUser = this.defaults?.user === null;
    this.view = this.defaults.view;
    this.userObserver.next(this.defaults.user);
  }

  updateUser(user: User) {
    this.userObserver.next(user);
    this.newUser = false;
  }

  generatePassword() {
    this.dialog
      .open(PasswordResetComponent, {
        data: this.userObserver.value,
        width: '400px',
        panelClass: 'custom-modalbox',
      })
      .afterClosed()
      .subscribe(() => {});
  }

  deactivateUser() {
    this.confirmationService
      .openConfirmation('Are you sure want deactive user?')
      .subscribe((answer) => {
        if (answer)
          this.userService
            .patchUser({
              id: this.defaults?.user.id,
              active: 'NO',
            })
            .subscribe((reponse) => this.dialogRef.close());
      });
  }

  activateUser() {
    this.confirmationService
      .openConfirmation('Are you sure want to active user?')
      .subscribe((answer) => {
        if (answer)
          this.userService
            .patchUser({
              id: this.defaults?.user.id,
              active: 'YES',
            })
            .subscribe((reponse) => this.dialogRef.close());
      });
  }

  canEdit() {
    return !this.view;
  }
  save() {
    this.dialogRef.close();
  }
}
