import { Transfer } from '../../../../models/transfer.model';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
@Component({
  selector: 'wilmish-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss'],
})
export class TransferComponent implements OnInit {
  @Input() transfer: Transfer;
  @Output() updateTransfer = new EventEmitter<Transfer>();
  todo = [];
  done = [];
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    this.saveTransfer();
  }
  constructor() {}

  ngOnInit(): void {
    if (this.transfer) {
      this.todo = this.transfer.available.filter(
        (value) => !this.transfer.transfered.includes(value)
      );
      this.done = this.transfer.transfered;
    }
  }

  saveTransfer() {
    this.transfer.available = this.todo;
    this.transfer.transfered = this.done;
    this.updateTransfer.emit(this.transfer);
  }
}
