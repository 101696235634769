import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CalculatorService {
  private BACKEND_ENDPOINT = environment.apiUrl;
  private calculatorUrl = `${this.BACKEND_ENDPOINT}`;

  constructor(private http: HttpClient) {}

  getCardboardCodes(): Observable<any> {
    return this.http.get<any>(`${this.calculatorUrl}cardboards`);
  }

  getFefcoCodes(): Observable<any> {
    return this.http.get<any>(`${this.calculatorUrl}fefcos`);
  }

  getTransportations(): Observable<any> {
    return this.http.get<any>(`${this.calculatorUrl}transportations`);
  }

  submitCalculatorData(data: any): Observable<any> {
    return this.http.post<any>(`${this.calculatorUrl}calculator/summary`, data);
  }

  saveFormulas(data: any): Observable<any> {
    return this.http.post<any>(`${this.calculatorUrl}box-formulas/`, data);
  }

  getFormulas(fluteType: any, fefcoCode: any): Observable<any> {
    return this.http.get<any>(
      `${this.calculatorUrl}box-formulas?fluteType=${fluteType}&fefcoCode=${fefcoCode}`
    );
  }

  getFluteTypes(): Observable<any> {
    return this.http.get<any>(`${this.calculatorUrl}corrugations`);
  }

  getCardboards(): Observable<any> {
    return this.http.get<any>(`${this.calculatorUrl}cardboards`);
  }

  getAssembly(): Observable<any> {
    return this.http.get<any>(`${this.calculatorUrl}assembly-lines`);
  }

  createCarboard(data: any): Observable<any> {
    return this.http.post<any>(`${this.calculatorUrl}cardboards`, data);
  }

  deleteCarboard(data: any): Observable<any> {
    return this.http.delete<any>(`${this.calculatorUrl}cardboards/${data}`);
  }

  createFefco(data: any): Observable<any> {
    return this.http.post<any>(`${this.calculatorUrl}fefcos`, data);
  }

  deleteFefco(data: any): Observable<any> {
    return this.http.delete<any>(`${this.calculatorUrl}fefcos/${data}`);
  }

  createTransportation(data: any): Observable<any> {
    return this.http.post<any>(`${this.calculatorUrl}transportations`, data);
  }

  createAssembly(data: any): Observable<any> {
    return this.http.post<any>(`${this.calculatorUrl}assembly-lines`, data);
  }

  deleteTransportation(data: any): Observable<any> {
    return this.http.delete<any>(
      `${this.calculatorUrl}transportations/${data}`
    );
  }

  exportCardboards() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: new HttpParams({}),
    };
    const dowloadOptions: any = {
      headers: httpOptions.headers,
      params: httpOptions.params,
      responseType: 'blob' || 'json',
    };

    return this.http.get(
      `${this.calculatorUrl}cardboards/price/export`,
      dowloadOptions
    );
  }

  importElements(formData) {
    return this.http.post(
      `${this.calculatorUrl}cardboards/price/import`,
      formData
    );
  }
}
