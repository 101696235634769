import { Component, Input, OnInit } from '@angular/core';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import icEdit from '@iconify/icons-ic/twotone-edit';
import { User } from 'src/app/models/user.model';
import { MatDialog } from '@angular/material/dialog';
import {
  ROLE_LIST,
  ROLE_LIST_WITH_VALUES,
} from 'src/app/static-data/roles.data';
import { Role } from 'src/app/models/role.model';
import { TransferModalComponent } from 'src/app/pages/users/transfer-modal/transfer-modal.component';

@Component({
  selector: 'wilmish-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class RolesComponent implements OnInit {
  @Input() user: User;
  @Input() canEdit: boolean;
  roleList;
  allRoles = ROLE_LIST_WITH_VALUES;
  rolesColumns: TableColumn<any>[] = [
    {
      label: 'Nr',
      property: 'id',
      type: 'id',
      visible: true,
    },
    {
      label: 'Role',
      property: 'displayName',
      type: 'text',
      visible: true,
    },
  ];
  icEdit = icEdit;
  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    this.user.roles = this.user?.roles?.map((role) => new Role(role)) || [];
    this.roleList = this.user.roles || [];
  }

  openEditRolesList() {
    if (this.roleList) {
      this.dialog
        .open(TransferModalComponent, {
          data: {
            user: this.user,
            context: 'Roles',
            roles: this.allRoles,
          },
          width: '600px',
          panelClass: 'custom-modalbox',
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.roleList = result.roles;
          }
        });
    }
  }
}
