<div
  #originRef
  (click)="showPopover(originRef)"
  [class.bg-hover]="dropdownOpen"
  class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-3 hover:bg-hover"
  matRipple
>
  <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3" fxHide.xs>
    {{ this.currentUser.name }}
  </div>
  <div
    class="rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary-light"
  >
    <ngx-avatar size="30" [name]="this.currentUser.name"></ngx-avatar>
    <!-- <img
      [matTooltip]="this.currentUser.name"
      [src]="this.currentUser.imageSrc"
      class="avatar overlapping"
    /> -->
  </div>
</div>
