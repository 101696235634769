import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import icClose from '@iconify/icons-ic/twotone-close';
import icLock from '@iconify/icons-ic/twotone-lock';
import icCopy from '@iconify/icons-ic/twotone-content-copy';
import { ConfirmationService } from 'src/@wilmish/services/confirmation-service/confirmation.service';
import { UserService } from 'src/app/services/user/user.service';
@Component({
  selector: 'wilmish-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  icClose = icClose;
  icLock = icLock;
  icCopy = icCopy;
  password = '';
  isGenerated = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private defaults: any,
    private dialogRef: MatDialogRef<any>,
    private confirmationService: ConfirmationService,
    private userService: UserService
  ) {}

  ngOnInit(): void {}

  save() {
    this.confirmationService
      .openConfirmation('Are you sure want to generate new password for user?')
      .subscribe((answer) => {
        if (answer) {
          this.userService
            .generatePassword(this.defaults)
            .subscribe((response: any) => {
              this.password = response.password;
              this.isGenerated = true;
            });
        }
      });
  }
}
