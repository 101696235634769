<div fxLayout="row" fxLayoutAlign="start center" mat-dialog-title>
  <mat-icon
    fxFlex="auto"
    [icIcon]="icLock"
    size="25px"
    class="text-primary"
  ></mat-icon>
  <label fxFlex="auto">Generate new password</label>
  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon [icIcon]="icClose"></mat-icon>
  </button>
</div>

<mat-dialog-content style="height: 60px" *ngIf="isGenerated">
  <mat-form-field class="w-full">
    <mat-label>New password</mat-label>
    <input
      matInput
      placeholder="Password"
      [(ngModel)]="password"
      readonly="true"
    />

    <button
      [cdkCopyToClipboard]="password"
      mat-button
      fxFlex
      mat-icon-button
      matSuffix
      style="text-align: -webkit-center"
    >
      <mat-icon [icIcon]="icCopy" color="primary" size="1rem"></mat-icon>
    </button>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions style="display: grid" *ngIf="!isGenerated">
  <button mat-button submit class="button-primary" (click)="save()">
    Generate
  </button>
</mat-dialog-actions>
