import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@vex/animations/scale-fade-in.animation';
import { stagger20ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { trackById } from 'src/@vex/utils/track-by';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'wilmish-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard',
      } as MatFormFieldDefaultOptions,
    },
  ],
  animations: [stagger20ms, fadeInUp400ms, scaleFadeIn400ms],
})
export class DataTableComponent<T> implements OnInit, OnChanges {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() data: T[];
  @Input() columns: TableColumn<T>[];
  @Output() openRow = new EventEmitter();

  visibleColumns: Array<keyof T | string>;
  dataSource = new MatTableDataSource<T>();
  selection = new SelectionModel<any>(false, []);
  trackById = trackById;
  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columns) {
      this.visibleColumns = this.columns
        .filter((column) => column.visible)
        .map((column) => column.property);
    }

    if (changes.data) {
      this.dataSource.data = this.data;
      this.dataSource.sort = this.sort;
    }
  }

  setSort(active: string, direction: 'asc' | 'desc'): void {
    this.sort.sort({ id: active, start: direction, disableClear: false });
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
}
