import { CalculatorService } from 'src/app/services/calculator/calculator.service';
import { ConfirmationService } from '../../../../services/confirmation-service/confirmation.service';
import { Component, Input, OnInit, EventEmitter } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { User } from 'src/app/models/user.model';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icEmail from '@iconify/icons-ic/twotone-mail';
import icGender from '@iconify/icons-fa-solid/genderless';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icDone from '@iconify/icons-ic/twotone-done-outline';
import icAdd from '@iconify/icons-ic/twotone-add-box';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icCancel from '@iconify/icons-ic/twotone-cancel';
import icStarBorder from '@iconify/icons-ic/twotone-star-border';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icEuro from '@iconify/icons-ic/twotone-euro';
import { Output } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'wilmish-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @Input() user: User;
  @Input() canEdit: Boolean;
  @Output() updateUser = new EventEmitter<User>();
  defaultAvatar =
    'https://icons-for-free.com/iconfiles/png/512/business+costume+male+man+office+user+icon-1320196264882354682.png';
  form: FormGroup;
  icEdit = icEdit;
  icEmail = icEmail;
  icPhone = icPhone;
  icGender = icGender;
  icStarBorder = icStarBorder;
  icPersonAdd = icPersonAdd;
  icCancel = icCancel;
  icDone = icDone;
  icEuro = icEuro;
  icAdd = icAdd;
  icDelete = icDelete;
  // 'create' | 'update' | 'watch' | 'new'
  mode = 'create';

  locations;
  managerList;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private calculatorService: CalculatorService
  ) {}

  ngOnInit() {
    // ... your existing code ...
    console.log('updaste');
    let filter: any = {};
    filter.list = [{ field: 'roles', valueList: ['Manager', 'Admin'] }];

    const usersObservable = this.userService
      .getUsers(filter)
      .pipe(
        map((data) => data.map((d) => ({ id: d.id, fullName: d.fullName })))
      );

    const transportationsObservable =
      this.calculatorService.getTransportations();

    forkJoin([usersObservable, transportationsObservable]).subscribe(
      ([managerList, locations]) => {
        this.managerList = managerList;
        this.locations = locations;

        if (this.user != null) {
          this.mode = 'watch';
          this.setUpWatchForm();
        } else {
          this.setUpForm();
        }
      }
    );
  }

  updateMode() {
    this.mode = 'update';
    this.setUpForm();
  }

  watchMode() {
    this.mode = 'watch';
  }

  isAdmin() {
    return this.authService.isAdmin();
  }

  cancelUpdate() {
    this.watchMode();
    this.setUpWatchForm();
  }

  setUpForm() {
    this.form = this.fb.group({
      imageSrc: this.user?.imageSrc || this.defaultAvatar,
      firstName: [this.user?.firstName || '', Validators.required],
      lastName: [this.user?.lastName || '', Validators.required],
      email: [
        this.user?.email || '',
        {
          validators: [Validators.required, Validators.email],
          asyncValidators: [this.emailExistValidator()],
        },
      ],
      phoneNumber: this.user?.phoneNumber || '',
      description: this.user?.description || '',
      marginPriceRanges: this.fb.array(
        this.user?.marginPriceRanges?.map((value) =>
          this.fb.group({
            fromArea: value.fromArea || 0,
            marginPrice: value.marginPrice || 0,
          })
        ) || []
      ),
      company: this.user?.company || '',
      transportationLocation: this.user?.transportationLocation || '',
      managedBy:
        this.user?.managedByUserId || this.authService.currentUserValue.user.id,
    });
  }

  setUpWatchForm() {
    this.form = this.fb.group({
      imageSrc: this.user?.imageSrc || this.defaultAvatar,
      firstName: [this.user?.firstName],
      lastName: [this.user?.lastName],
      email: [this.user?.email || ''],
      phoneNumber: this.user?.phoneNumber || '',
      description: this.user?.description || '',
      company: this.user?.company || '',
      transportationLocation: this.user?.transportationLocation || '',
      marginPriceRanges: this.fb.array(
        this.user?.marginPriceRanges?.map((value) =>
          this.fb.group({
            fromArea: value.fromArea || 0,
            marginPrice: value.marginPrice || 0,
          })
        ) || []
      ),
      managedBy:
        this.mode === 'watch'
          ? this.user?.managedByUserFullName
          : this.user?.managedByUserId || '',
    });
  }

  get marginPriceRanges(): FormArray {
    return this.form.get('marginPriceRanges') as FormArray;
  }

  addRange(fromArea, marginPrice): void {
    this.marginPriceRanges.push(
      this.fb.group({
        fromArea: fromArea || '',
        marginPrice: marginPrice || '',
      })
    );
  }

  removeRange(index: number): void {
    this.marginPriceRanges.removeAt(index);
  }

  areChanges() {
    return (
      this.form.value.imageSrc !== this.user?.imageSrc ||
      this.form.value.firstName !== this.user?.firstName ||
      this.form.value.lastName !== this.user?.lastName ||
      this.form.value.email !== this.user?.email ||
      this.form.value.phoneNumber !== this.user?.phoneNumber ||
      this.form.value.description !== this.user?.description ||
      this.form.value.marginPriceRanges !== this.user?.marginPriceRanges ||
      this.form.value.company !== this.user?.company ||
      this.form.value.transportationLocation !==
        this.user?.transportationLocation ||
      this.form.value.managedBy !== this.user?.managedByUserId ||
      this.form.value.marginPriceRanges !== this.user?.marginPriceRanges
    );
  }

  save() {
    this.confirmationService
      .openConfirmation('Are you sure want to create user?')
      .subscribe((answer) => {
        if (answer) {
          var prepareForCreation = this.form.value;
          prepareForCreation.managedByUserId = prepareForCreation.managedBy;
          this.userService
            .addUser(new User(prepareForCreation))
            .subscribe((user) => {
              this.updateUser.emit(new User(user));
            });
        }
      });
  }

  update() {
    let userToSend = this.form.value;
    userToSend.id = this.user.id;
    userToSend.email = null;
    userToSend.managedByUserId = userToSend.managedBy;

    const element = this.managerList.find(
      (x) => x.id === this.form.value.managedBy
    );

    userToSend.managedByUserFullName = element.fullName;

    console.log(userToSend);
    this.confirmationService
      .openConfirmation('Are you sure want to change user data?')
      .subscribe((answer) => {
        if (answer) {
          this.userService.patchUser(new User(userToSend)).subscribe((user) => {
            userToSend.email = this.user.email;
            this.watchMode();
            this.updateUser.emit(new User(userToSend));
            this.ngOnInit();
          });
        }
      });
  }

  getErrorCount(container: FormGroup): number {
    let errorCount = 0;
    for (let controlKey in container.controls) {
      if (container.controls.hasOwnProperty(controlKey)) {
        if (container.controls[controlKey].errors) {
          errorCount += Object.keys(
            container.controls[controlKey].errors
          ).length;
        }
      }
    }
    return errorCount;
  }

  getControlsErrorCount(controls: AbstractControl): number {
    let errorCount = 0;
    if (controls.errors) {
      errorCount += Object.keys(controls.errors).length;
    }
    return errorCount;
  }

  emailExistValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> | null => {
      let filter: any = {};
      filter.list = [{ field: 'email', valueList: [control.value] }];
      return this.userService.getUserList(filter, '0', '1').pipe(
        map((res: any) => {
          return res.body.length === 0 || control.value === this.user.email
            ? null
            : { emailExist: true };
        })
      );
    };
  }

  checkEmailExistance(email: string) {
    let filter: any = {};
    filter.list = [{ field: 'email', valueList: [email] }];
    this.userService.getUserList(filter, '0', '1').subscribe((res: any) => {
      return res.body.length();
    });
  }
}
