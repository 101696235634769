import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  BACKEND_ENDPOINT = environment.apiUrl;

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private roles;

  constructor(private httpClient: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.roles = JSON.parse(
      localStorage.getItem('currentUser')
    )?.user?.roles?.map((role) => role.name);
    // this.logout();
  }

  authUser(email, password) {
    return this.httpClient.post<{ accessToken: string }>(
      this.BACKEND_ENDPOINT + 'auth/signin',
      {
        email,
        password,
      }
    );
  }

  setCurrentUser(authResponse) {
    this.roles = authResponse.user.roles?.map((role) => role.name);
    this.currentUserSubject.next(authResponse);
    localStorage.setItem('currentUser', JSON.stringify(authResponse));
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  isAdmin() {
    return (
      this.currentUserSubject.value.user.roles.find(
        (data) => data.name === 'ROLE_ADMIN'
      ) != undefined
    );
  }
}
