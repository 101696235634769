export const ROLE_LIST: string[] = ['Admin', 'Manager', 'Client'];

export const ROLE_LIST_WITH_VALUES: any[] = [
  {
    name: 'ROLE_ADMIN',
    displayName: 'Admin',
  },
  {
    name: 'ROLE_MANAGER',
    displayName: 'Manager',
  },
  {
    name: 'ROLE_CLIENT',
    displayName: 'Client',
  },
];
